<template>
	<div>
		<ZyroFieldToggle
			id="toggleShowCart"
			v-qa="`editblocknavigation-tabcart-iscartvisible-${isCartVisible}`"
			:label="$t('builder.editBlockNavigation.tabCart.showCart')"
			:checked="isCartVisible"
			@input="isCartVisible = $event"
		/>
		<template v-if="isCartVisible">
			<ZyroSeparator />
			<ZyroFieldText
				v-model="cartIconText"
				v-qa="`editblocknavigation-tabcart-carticontext-${cartIconText}`"
				:label="$t('builder.editBlockNavigation.tabCart.cartIconText')"
			/>
			<ZyroSeparator />
			<ZyroIconSelect
				v-qa="`editblocknavigation-tabcart-carticonsize-${cartIconSize}`"
				:options="ICON_SIZE_OPTIONS"
				:label="$t('builder.editBlockNavigation.tabCart.iconSize')"
				label-padding="20px 0 16px 0"
				:items-per-row="3"
				:current.sync="cartIconSize"
			/>
		</template>
	</div>
</template>

<script>
import {
	mapGetters,
	mapMutations,
} from 'vuex';

import i18n from '@/i18n/setup';

const ICON_SIZE_OPTIONS = {
	'24px': {
		name: i18n.t('common.small'),
		icon: 'shopping-bag',
		iconDimensions: '24px',
	},
	'32px': {
		name: i18n.t('common.medium'),
		icon: 'shopping-bag',
		iconDimensions: '32px',
	},
	'40px': {
		name: i18n.t('common.large'),
		icon: 'shopping-bag',
		iconDimensions: '40px',
	},
};

export default {
	setup() {
		return { ICON_SIZE_OPTIONS };
	},
	computed: {
		...mapGetters(['currentBlock']),
		isCartVisible: {
			get() {
				return this.currentBlock.settings.isCartVisible;
			},
			set(event) {
				this.setBlockData({ data: { settings: { isCartVisible: event.target.checked } } });
			},
		},
		cartIconText: {
			get() {
				return this.currentBlock.settings.cartText;
			},
			set(event) {
				this.setBlockData({ data: { settings: { cartText: event.target.value } } });
			},
		},
		cartIconSize: {
			get() {
				return this.currentBlock.settings.styles.cartIconSize;
			},
			set(value) {
				this.setBlockData({ data: { settings: { styles: { cartIconSize: value } } } });
			},
		},
	},
	methods: { ...mapMutations(['setBlockData']) },
};
</script>
