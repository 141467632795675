<template>
	<div class="navigation-style">
		<ZyroLabel class="navigation-style__header">
			{{ $t('builder.editBlockNavigation.tabStyle.headerBackground') }}
		</ZyroLabel>
		<ZyroBackground
			:background-object.sync="background"
		/>
		<ZyroFieldToggle
			id="showLogoToggle"
			v-qa="`editblocknavigation-tabstyle-istransparent-${isTransparent}`"
			:label="$t('builder.editBlockNavigation.tabStyle.transparentHeader')"
			:checked="isTransparent"
			@input="isTransparent = $event"
		/>
		<ZyroSeparator />
		<div class="navigation-style__nav-link-color navigation-style__nav-link-color--margin-top">
			<ZyroLabel>
				{{ $t('builder.editBlockNavigation.tabStyle.textColor') }}
			</ZyroLabel>
			<ZyroColorPicker
				v-model="navLinkColor"
				v-qa="'editblocknavigation-tabstyle-headertextcolor-btn'"
				:default-mode="$options.COLOR_PICKER_MODE_FULL"
			/>
		</div>
		<div class="navigation-style__nav-link-color">
			<ZyroLabel class="z-body-small">
				{{ $t('builder.editBlockNavigation.tabStyle.hoverColor') }}
			</ZyroLabel>
			<ZyroColorPicker
				v-model="navLinkHoverColor"
				v-qa="'editblocknavigation-tabstyle-hovercolor-btn'"
				:default-mode="$options.COLOR_PICKER_MODE_FULL"
			/>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { COLOR_PICKER_MODE_FULL } from '@/components/global/zyro-color-picker/constants';
import { COLOR_LIGHT } from '@/constants/colors';
import { useElementContrast } from '@/use/useElementContrast';

const DEFAULT_BACKGROUND = {
	color: '#ffffff',
	current: 'color',
};

export default {
	COLOR_PICKER_MODE_FULL,
	setup() {
		const {
			getContrastColor,
			getLighterColorShade,
			getDarkerColorShade,
		} = useElementContrast();

		return {
			getContrastColor,
			getLighterColorShade,
			getDarkerColorShade,
		};
	},
	computed: {
		...mapState(['website']),
		...mapGetters(['currentBlock']),
		isTransparent: {
			get() {
				return this.currentBlock.background.isTransparent;
			},
			set(event) {
				this.setBlockData({ data: { background: { isTransparent: event.target.checked } } });
			},
		},
		background: {
			get() {
				const { background } = this.currentBlock;

				return ('current' in background)
					? background : DEFAULT_BACKGROUND;
			},
			set(background) {
				const {
					image,
					current,
					color,
					'overlay-opacity': overlayOpacity,
				} = background;
				const constrastColor = this.getContrastColor(color);
				const colorShade = constrastColor === COLOR_LIGHT
					? this.getLighterColorShade(color)
					: this.getDarkerColorShade(color);

				this.setBlockData({
					data: {
						settings: { styles: { contrastBackgroundColor: colorShade } },
						background: {
							color,
							image,
							current,
							'overlay-opacity': overlayOpacity,
						},
					},
				});
			},
		},
		navLinkColor: {
			get() {
				return this.website.styles['nav-link'].color;
			},
			set(value) {
				this.setStyleProperty({
					element: 'nav-link',
					property: 'color',
					value,
				});
			},
		},
		navLinkHoverColor: {
			get() {
				const { styles } = this.website;

				return styles['nav-link']['color-hover'] || styles['nav-link'].color;
			},
			set(value) {
				this.setStyleProperty({
					element: 'nav-link',
					property: 'color-hover',
					value,
				});
			},
		},
	},
	methods: mapMutations([
		'setBlockData',
		'setStyleProperty',
	]),
};
</script>

<style scoped lang="scss">
	.navigation-style {
		&__header {
			margin-bottom: 16px;
		}

		&__nav-link-color {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&--margin-top {
				margin-top: 12px;
			}
		}
	}
</style>
