<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('builder.editBlockNavigation.title')"
		:tabs="tabs"
		:current-tab="currentTab"
		@change="currentTab = $event"
		@close="$emit('close')"
	>
		<Component
			:is="currentTab.component"
			@hook:mounted="saveBlockDataBeforeEdit"
			@hook:beforeDestroy="saveBlockDataToHistory"
		/>
	</ZyroPopupCard>
</template>

<script>
import {
	mapGetters,
	mapMutations,
	mapState,
} from 'vuex';

import i18n from '@/i18n/setup';
import { cloneDeep } from '@/utils/object';

import EditBlockNavigationTabCart from './EditBlockNavigationTabCart.vue';
import EditBlockNavigationTabLayout from './EditBlockNavigationTabLayout.vue';
import EditBlockNavigationTabLogo from './EditBlockNavigationTabLogo.vue';
import EditBlockNavigationTabStyle from './EditBlockNavigationTabStyle.vue';

const TAB_ID_CART = 'cart';

const TABS = [
	{
		id: 'layout',
		title: i18n.t('common.layout'),
		component: 'EditBlockNavigationTabLayout',
	},
	{
		id: 'logo',
		title: i18n.t('common.logo'),
		component: 'EditBlockNavigationTabLogo',
	},
	{
		id: TAB_ID_CART,
		title: i18n.t('common.cart'),
		component: 'EditBlockNavigationTabCart',
	},
	{
		id: 'style',
		title: i18n.t('common.style'),
		component: 'EditBlockNavigationTabStyle',
	},
];

export default {
	components: {
		EditBlockNavigationTabCart,
		EditBlockNavigationTabLayout,
		EditBlockNavigationTabLogo,
		EditBlockNavigationTabStyle,
	},
	props: {
		startTabId: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			currentTab: '',
			currentBlockBeforeEdit: null,
			blockId: null,
		};
	},
	computed: {
		...mapGetters('onlineStore', ['isSiteWithEcwid']),
		...mapState(['currentBlockId']),
		...mapGetters(['currentBlock']),
		tabs() {
			if (this.isSiteWithEcwid) {
				return TABS;
			}

			return TABS.filter((tab) => tab.id !== TAB_ID_CART);
		},
	},
	created() {
		this.currentTab = TABS.find((tab) => tab.id === this.startTabId) ?? TABS[0];
	},
	methods: {
		...mapMutations(['pushBlockDataToHistory']),
		saveBlockDataToHistory() {
			this.pushBlockDataToHistory({
				blockId: this.id,
				oldData: this.currentBlockBeforeEdit,
			});
		},
		saveBlockDataBeforeEdit() {
			this.id = this.currentBlockId;
			this.currentBlockBeforeEdit = cloneDeep(this.currentBlock);
		},
	},
};
</script>
