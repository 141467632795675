<template>
	<div class="layout">
		<ZyroFieldToggle
			id="isStickyToggle"
			v-qa="`editblocknavigation-tablayout-issticky-${isSticky}`"
			:checked="isSticky"
			:label="$t('builder.editBlockNavigation.tabLayout.stickyHeader')"
			@input="isSticky = $event"
		/>
		<ZyroSeparator />
		<div class="layout__menu-position">
			<ZyroLabel class="layout__menu-position-label">
				{{ $t('builder.editBlockNavigation.tabLayout.menuPosition') }}
			</ZyroLabel>
			<ZyroIconControls
				class="layout__menu-position-icons"
				:current.sync="navigationPlacement"
				:icons="$options.iconsJustify"
				:toggleable="false"
			/>
		</div>
		<ZyroSeparator />
		<ZyroFieldRange
			v-model="menuItemSpacing"
			:qa="`editblocknavigation-tablayout-menuitemspacing-${menuItemSpacing}`"
			min="0"
			max="64"
			:label="$t('builder.editBlockNavigation.tabLayout.itemSpacing')"
		/>
		<ZyroSeparator />
		<ZyroCssShorthandControlRange
			:qa="`editblocknavigation-tablayout-padding-${padding.replace(/ /g, '-')}`"
			class="layout__padding"
			:label="$t('common.padding')"
			:value.sync="padding"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { useBuilderStyles } from '@/components/builder-controls/useBuilderStyles';

const iconsJustify = [
	{
		value: 'left',
		icon: 'align-left-h',
	},
	{
		value: 'center',
		icon: 'align-middle-h',
	},
	{
		value: 'right',
		icon: 'align-right-h',
	},
];

export default {
	iconsJustify,
	setup() {
		const {
			getStyleValue,
			getStyleKey,
		} = useBuilderStyles();

		return {
			getStyleValue,
			getStyleKey,
		};
	},
	computed: {
		...mapGetters(['currentBlock']),
		...mapState('gui', ['isMobileView']),
		isSticky: {
			get() {
				return this.currentBlock.settings.isSticky;
			},
			set(event) {
				this.setBlockData({ data: { settings: { isSticky: event.target.checked } } });
			},
		},
		menuItemSpacing: {
			get() {
				return Number.parseInt(this.getStyleValue('menu-item-spacing', this.currentBlock.settings.styles), 10);
			},
			set(event) {
				const key = this.getStyleKey('menu-item-spacing');

				this.setBlockData({ data: { settings: { styles: { [key]: `${event.target.value}px` } } } });
			},
		},
		padding: {
			get() {
				return this.getStyleValue('padding', this.currentBlock.settings.styles);
			},
			set(newValue) {
				const key = this.getStyleKey('padding');

				this.setBlockData({ data: { settings: { styles: { [key]: newValue } } } });
			},
		},
		navigationPlacement: {
			get() {
				return this.isMobileView
					? this.currentBlock.settings['m-navigationPlacement'] || this.currentBlock.settings.navigationPlacement
					: this.currentBlock.settings.navigationPlacement;
			},
			set(value) {
				const key = this.isMobileView ? 'm-navigationPlacement' : 'navigationPlacement';

				this.setBlockData({ data: { settings: { [key]: value } } });
			},
		},
	},
	methods: { ...mapMutations(['setBlockData']) },
};
</script>

<style lang="scss" scoped>
.layout {
	&__menu-position {
		&-label {
			margin: 16px 0 8px;
		}

		&-icons {
			margin: 8px 0;
		}
	}

	&__padding {
		margin: 16px 0 8px;
	}
}
</style>
