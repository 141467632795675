<template>
	<div class="layout">
		<ZyroFieldToggle
			id="showLogoToggle"
			v-qa="`editblocknavigation-tablogo-showLogo-${showLogo}`"
			:label="$t('builder.editBlockNavigation.tabLogo.showLogo')"
			:checked="showLogo"
			@input="showLogo = $event"
		/>
		<template v-if="showLogo">
			<ZyroImageSelector
				:value="logoUrl"
				@input="logoUrl = $event.url"
			/>
			<i18n
				tag="p"
				class="layout__generator-subtitle z-body-small"
				path="builder.editBlockNavigation.tabLogo.logoGeneratorLink"
			>
				<a
					class="z-link"
					href="https://logo.zyro.com/"
					target="_blank"
					@click="logLogoClick"
					v-text="$t('builder.editBlockNavigation.tabLogo.logoGeneratorLinkText')"
				/>
			</i18n>
			<ZyroFieldRange
				v-model="logoWidth"
				:qa="`editblocknavigation-tablogo-logowidth-${logoWidth}`"
				min="30"
				max="250"
				:label="$t('builder.editBlockNavigation.tabLogo.logoWidth')"
			/>
			<ZyroSeparator />
			<ZyroFieldRange
				v-model="spaceBetweenMenu"
				:qa="`editblocknavigation-tablogo-spacebetweenmenu-${spaceBetweenMenu}`"
				min="0"
				max="64"
				:label="$t('builder.editBlockNavigation.tabLogo.itemSpacing')"
			/>
			<template v-if="!isCartVisibleAndMobileView">
				<div
					class="layout__menu-position"
				>
					<ZyroLabel class="layout__menu-position-label">
						{{ $t('builder.editBlockNavigation.tabLogo.logoPosition') }}
					</ZyroLabel>
					<ZyroIconControls
						class="layout__menu-position-icons"
						:current.sync="logoPlacement"
						:icons="$options.iconsJustify"
						:toggleable="false"
					/>
				</div>
			</template>
		</template>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';

const iconsJustify = [
	{
		value: 'left',
		icon: 'align-left-h',
	},
	{
		value: 'center',
		icon: 'align-middle-h',
	},
	{
		value: 'right',
		icon: 'align-right-h',
	},
];

export default {
	iconsJustify,
	data() {
		return { align: '' };
	},
	computed: {
		...mapState('gui', ['isMobileView']),
		...mapState(['website']),
		...mapGetters(['currentBlock']),
		...mapGetters('onlineStore', ['isSiteWithEcwid']),
		showLogo: {
			get() {
				return this.currentBlock.settings.showLogo;
			},
			set(event) {
				this.setBlockData({ data: { settings: { showLogo: event.target.checked } } });
			},
		},
		logoUrl: {
			get() {
				return this.currentBlock.settings.logoUrl;
			},
			set(newValue) {
				this.setBlockData({ data: { settings: { logoUrl: newValue } } });
			},
		},
		spaceBetweenMenu: {
			get() {
				return Number.parseInt(this.currentBlock.settings.styles['space-between-menu'], 10);
			},
			set(event) {
				this.setBlockData({ data: { settings: { styles: { 'space-between-menu': `${event.target.value}px` } } } });
			},
		},
		logoWidth: {
			get() {
				const key = this.isMobileView ? 'm-logo-width' : 'logo-width';

				return Number.parseInt(this.currentBlock.settings.styles[key], 10);
			},
			set(event) {
				const key = this.isMobileView ? 'm-logo-width' : 'logo-width';

				this.setBlockData({ data: { settings: { styles: { [key]: `${event.target.value}px` } } } });
			},
		},
		logoPlacement: {
			get() {
				return this.isMobileView
					? this.currentBlock.settings['m-logoPlacement'] || this.currentBlock.settings.logoPlacement
					: this.currentBlock.settings.logoPlacement;
			},
			set(value) {
				const key = this.isMobileView ? 'm-logoPlacement' : 'logoPlacement';

				this.setBlockData({ data: { settings: { [key]: value } } });
			},
		},
		navigationPlacement() {
			const key = this.isMobileView ? 'm-navigationPlacement' : 'navigationPlacement';

			return this.currentBlock.settings[key];
		},
		isCartVisibleAndMobileView: ({
			isMobileView,
			isSiteWithEcwid,
			website,
		}) => isMobileView && isSiteWithEcwid && website.blocks.navigation.settings.isCartVisible,

	},
	methods: {
		...mapMutations(['setBlockData']),
		logLogoClick() {
			EventLogApi.logEvent({ eventName: 'builder.header.replace_logo' });
		},
	},
};
</script>

<style lang="scss" scoped>
.layout {
	&__menu-position-label {
		margin: 16px 0 8px;
	}

	&__menu-position-icons {
		margin: 8px 0;
	}

	&__generator-subtitle {
		margin-top: 8px;
		color: $grey-800;
	}
}
</style>
